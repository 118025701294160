.ring{
    background-color: rgb(236, 230, 225);
}

.back{
    background-color: rgb(197, 181, 157);
}


.backmain{
    background-color: rgb(219, 203, 178);
}

.text5 h1{
color: rgb(100, 8, 78)
}

.text5 h5{
    color: rgb(100, 8, 78)
    }

    .colornew{
        background-color: #F9F2EA;
        padding: 4px;
    }

    .blink { 
        animation: blink-animation 1s infinite; 
      } 
        
      @keyframes blink-animation { 
        0% { 
          opacity: 1; 
        } 
        50% { 
          opacity: 0; 
        } 
        100% { 
          opacity: 1; 
        } 
      }
    