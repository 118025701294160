.no1jewellerskrishnagar{
    background-color: aliceblue;
}


.krishnagarbuttoncall{
    background-color: #c4048a; /* Green */
    border: none;
    color: rgb(246, 248, 248);
    padding: 5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
  }

  .krishnagarbuttoncallwhatsapp{
    background-color: #1b7f3b; /* Green */
    border: none;
    color: rgb(246, 248, 248);
    padding: 5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
  }